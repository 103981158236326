import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  /* default */
  .MuiInput-underline:before {
    border-bottom: 2px solid white;
  }
  /* hover (double-ampersand needed for specificity reasons. */
  && .MuiInput-underline:hover:before {
    border-bottom: 2px solid red;
  }
  /* focused */
  .MuiInput-underline:after {
    border-bottom: 2px solid white;
  }
  .MuiFormLabel-root{
    color: white;
    font-family: Montserrat;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: white;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: white;
  }
  MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: red;
  }
`;


export default StyledTextField;
