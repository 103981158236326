/* import React from "react"
import { Link } from "gatsby"
import PropTypes from 'prop-types';
//import { makeStyles} from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core';
//import Facebook from '../images/facebook.svg'
//import Map from './map'
import { withStyles} from '@material-ui/core/styles';
//import GetVisits from '../api/visits/get';
//import TextField from './customTextfield'
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const styles = theme =>({
    footer: {
      //flexGrow: 1,
      width: '100%',
      //height: '30vh',
      minWidth: '500px',
      backgroundColor: '#252c30',
      position:"relative",
      fontFamily: 'Open Sans, sans-serif',
      textAlign: 'left',
      left:"0px",
      bottom:"0px",
      marginTop:"10px",
      color: 'white',
      '@media (max-width: 477px)': {
       
        display:"none"
      },
    },
    visits:{
      backgroundColor: '#0b1310',
      padding: '1em',
    },
    
    link: {
      textDecoration: 'none',
      color: '#75828f',
      fontSize: '0.875em',
      //paddingBottom: 10,
      '&:hover': {
        //background: "#f00",
        //fontWeight: 'bold',
        color: 'white'
     },
    },
    siteTitle:{
        fontSize: '1.2em',
        paddingBottom: '0.5em',
        paddingTop: '2.3em',
        margin: 0,
        width:"100%",
        height:"100%",
        '@media (max-width: 477px)': {
            display:"none"
          },
    },
    container:{
      padding: 0,
    },
    master:{
      paddingLeft: '3em',
      paddingRight: '3em',
      paddingBottom: '1.5em'
    },
    phone:{
      color: '#75828f',
      fontSize: '1em',
      paddingTop: '0.4em',
    }, 
    icon:{
      fontSize: '1em'
    }
  });

/* export default ()=>{
    const classes = useStyles();   
    const [name,setName]=useState('')
    const [phone, setPhone]= useState('')
    const [email, setEmail]= useState('')
    const [body, setBody]=useState('')
    const sendMail=()=> {
        const link = "mailto:soysitimm.ctm@gmail.com"
                 + "?cc="+email
                 + "&subject=" + escape("Contacto desde Pagina SITIMM de "+name)
                 + "&body=" + escape('Envia: '+ name +'\nTelefono: '+phone+'\nCorreo: '+ email+'\n'+body)
        ;
    
        window.location.href = link;
    } //
    class footer extends React.Component {
      constructor() {
        super();
        // No hagas esto!
        this.state = { 
          name: '',
          phone:'',
          email:'',
          body: '',
          visits: 0,
         };
       }
       sendMail=()=> {
        const link = "mailto:soysitimm.ctm@gmail.com"
                 + "?cc="+this.state.email
                 + "&subject=" + escape("Contacto desde Pagina SITIMM de "+this.state.name)
                 + "&body=" + escape('Envia: '+ this.state.name +'\nTelefono: '+this.state.phone+'\nCorreo: '+ this.state.email+'\n'+this.state.body)
        ;
    
        window.location.href = link;
    }
    async componentDidMount(){
      const visits=  {visits: 3588 }
      this.setState({visits: visits.visits?(visits.visits):('Cargando...')})
    }
    render (){
      const { classes } = this.props;
      return(
          <Grid container
          justify="center"
          alignItems="center"
          className={classes.footer}>
            <Grid 
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className={classes.master}>
              <Grid item xs={3} className={ classes.container}>
              <Typography className={classes.siteTitle}>SERVICIOS</Typography>                       
                  <Typography><Link className={classes.link} to='/'>Asesoría Jurídica</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Gestiones y Asesoría ante IMSS, INFONAVIT, AFORES y otras</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Negociación Colectiva</Link></Typography>     
                  <Typography><Link className={classes.link} to='/'>Formación Laboral y Productiva</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Formación Sindical</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Centro de Cómputo</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Biblioteca</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Educación Abierta</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Capacitación</Link></Typography>
              </Grid>
              <Grid item xs={3} className={ classes.container}>
              <Typography className={classes.siteTitle}>CURSOS - TALLERES</Typography>                       
                  <Typography><Link className={classes.link} to='/'>Trabajo en Equipo</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Comunicación Asertiva</Link></Typography>     
                  <Typography><Link className={classes.link} to='/'>Actitud de Calidad</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Inteligencia Emocional</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Control de las Emociones</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Oratoria</Link></Typography>
              </Grid>
              <Grid item xs={3} className={ classes.container}>
              <Typography className={classes.siteTitle}>TEMAS</Typography>                       
                  <Typography><Link className={classes.link} to='/'>Laboral</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Sindical</Link></Typography>
                  <Typography><Link className={classes.link} to='/'>Desarrollo Humano</Link></Typography>
              </Grid>
              <Grid item xs={3} className={ classes.container}>
                <Typography className={classes.siteTitle}>CONTÁCTANOS</Typography>                       
                  <Typography><Link className={classes.link} to='/oficinas'><LocationOnIcon className={classes.icon}/> Oficinas</Link></Typography>
                  <Typography><a className={classes.link} href='mailto:ceforma@prodigy.net.mx'><MailIcon className={classes.icon}/> ceforma@prodigy.net.mx</a></Typography>
                  <Typography className={classes.phone}><PhoneIcon className={classes.icon}/> Irapuato (462) 626 06 97</Typography>     
                  <Typography className={classes.phone}><PhoneIcon className={classes.icon}/> Irapuato (462) 626 25 50</Typography>
                  <Typography className={classes.phone}><PhoneIcon className={classes.icon}/> Celaya (461) 608 02 30</Typography>
                  <Typography className={classes.phone}><PhoneIcon className={classes.icon}/> Silao (472) 722 35 01</Typography>
                  <Typography className={classes.phone}><PhoneIcon className={classes.icon}/> Villagrán (411) 165 31 49</Typography>
                  <Typography className={classes.phone}><PhoneIcon className={classes.icon}/> San José Iturbide (419) 198 25 51</Typography>
              </Grid>
              </Grid>

              <Grid container
              xs={12}
              justify="center"
              alignItems="center"
              className={classes.visits}
              >
                <Typography>CEFORMA {new Date().getFullYear()} ® | The Blue Hive® | Visitas: {this.state.visits}</Typography>
              </Grid>
          </Grid>
    )}
}

footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(footer)



/* Servicios

    Asesoría Jurídica.
    Gestiones y Asesoría ante IMSS, INFONAVIT, AFORES y otras.
    Negociación Colectiva.
    Formación Laboral y Productiva.
    Formación Sindical.
    Centro de Cómputo.
    Biblioteca.
    Educación Abierta
    Capacitación

Cursos - Talleres

    Trabajo en Equipo.
    Comunicación Asertiva.
    Actitud de Calidad.
    Inteligencia Emocional.
    Control de las Emociones.
    Oratoria.

Temas

    Laboral
    Sindical
    Desarrollo Humano

Contáctanos

    Oficinas
    ceforma@prodigy.net.mx
    Irapuato (462) 626 06 97
    Irapuato (462) 626 25 50
    Celaya (461) 608 02 30
    Silao (472) 722 35 01
    Villagrán (411) 165 31 49
    San José Iturbide (419) 198 25 51

 */ 
import React from "react"
import { StaticQuery, graphql } from "gatsby";
import PropTypes from 'prop-types';
//import { makeStyles} from '@material-ui/core/styles';
import {Typography, Grid, Modal} from '@material-ui/core';
//import Facebook from '../images/facebook.svg'
import {Facebook, Twitter, YouTube} from '@material-ui/icons';
import sitimm from '../images/LOGO_SITIMM.png'
import bibliotecaIMG from '../images/BIBLIOTECA.png'
//import Map from './map'
import { withStyles} from '@material-ui/core/styles';
import GetVisits from '../api/visits/get';
import addVisits from '../api/visits/add';
import TextField from './customTextfield'
import EmailSend from "../api/contacto/send"

const styles = theme =>({
    footer: {
      //flexGrow: 1,
      width: '100%',
      //height: '30vh',
      //minWidth: '500px',
      backgroundColor: '#29333D',
      position:"relative",
      //fontFamily: 'Montserrat !important',
      left:"0px",
      bottom:"0px",
      marginTop:"10px",
      color: 'white',
    },
    siteMap:{
        
        width:"100%",
        height:"100%",
        '@media (max-width: 477px)': {
            display:"none"
          },
    },
    socialMedia:{
        color: 'white',//'#212529',
        width:"100%",
        height:"100%",
        //fontFamily: 'Montserrat !important',
    },
    siteMapText:{
        width: '100%',
        justifyContent:"center",
        display:"flex",
        color:'white',//"#212529",
        '@media (max-width: 477px)': {
            display:"none"
          },
    },
    siteMapCont:{
        columnCount:3,
        justifyContent:"center",
        alignItems:"center",
        height:"100%",
        display:"flex",
        '@media (max-width: 477px)': {
            display:"none"
          },
    },
    form:{
        maxWidth:"100%",
        maxHeight:"100%",
        height:"100%",
        '@media (max-width: 700px)': {
            display:"none"
          },
    },
    formTitle:{
        width: '100%',
        justifyContent:"center",
        alignItems:"center",
        display:"flex",
        color:"white",
        //fontFamily: 'Montserrat !important',
      
    },
    TextField:{
        width: '95%',
        color:"white !important",//"#212529",
        //fontFamily: 'Montserrat !important',
        '@media (max-width: 477px)': {
            display:"none"
          },
    },
    text:{fontSize: 15,
      //fontFamily: 'Montserrat !important',
      textAlign: 'center'
      
      },
    multilineColor:{
      color: '#FFFFFF'
    },
    s:{
        width: '100%',
        justifyContent:"center",
        alignItems:"center",
        display:"flex",
        color:'white', //"#212529",
        height:"50%",
        
    },
    s2:{
      width: '100%',
      justifyContent:"center",
      alignItems:"center",
      display:"flex",
      color:'white', //"#212529",
      height:"50%",
      background: "rgba(255, 255, 255, 0.7)",
      '@media (max-width: 477px)': {
          display:"none"
        },
  },
    customButton:{
        boxShadow: 'inset 0px 1px 0px 0px #54a3f7',
        background:'linear-gradient(to bottom, #007dc1 5%, #0061a7 100%)',
        backgroundColor:'#007dc1',
        borderRadius:'3px',
        border:'1px solid #124d77',
        display:'inline-block',
        cursor:'pointer',
        color:'#ffffff',
        //fontFamily:'Arial',
        fontSize:'13px',
        padding:'6px 24px',
        textDecoration: 'none',
        textShadow:'0px 1px 0px #154682',
        '&:hover':{
            background:'linear-gradient(to bottom, #0061a7 5%, #007dc1 100%)',
	        backgroundColor:'#0061a7'
        },
        '&:active': {
            position:'relative',
            top:'1px'
        },
        '@media (max-width: 477px)': {
            display:"none"
          },
        },
        B2:{
          '@media (max-width: 477px)': {
            display:"none"
          },
        },
        C3:{
          '@media (max-width: 477px)': {
            display:"none"
          },
        },
        modal: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          border: '2px solid #000',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },

  });

/* export default ()=>{
    const classes = useStyles();   
    const [name,setName]=useState('')
    const [phone, setPhone]= useState('')
    const [email, setEmail]= useState('')
    const [body, setBody]=useState('')
    const sendMail=()=> {
        const link = "mailto:soysitimm.ctm@gmail.com"
                 + "?cc="+email
                 + "&subject=" + escape("Contacto desde Pagina SITIMM de "+name)
                 + "&body=" + escape('Envia: '+ name +'\nTelefono: '+phone+'\nCorreo: '+ email+'\n'+body)
        ;
    
        window.location.href = link;
    } */
    class footer extends React.Component {
      constructor() {
        super();
        // No hagas esto!
        this.state = { 
          name: '',
          phone:'',
          email:'',
          body: '',
          visits: "0",
          offices: [],
          modalOpen: false,
          operationStatus: false,
         };
       }
       sendMail = async () => {
      /*   const link = "mailto:soysitimm.ctm@gmail.com"
                 + "?cc="+this.state.email
                 + "&subject=" + escape("Contacto desde Pagina SITIMM de "+this.state.name)
                 + "&body=" + escape('Envia: '+ this.state.name +'\nTelefono: '+this.state.phone+'\nCorreo: '+ this.state.email+'\n'+this.state.body)
        ;
    
        window.location.href = link; */
        try {
          await EmailSend({
            email: this.state.email,
            message: `Contacto desde Pagina CEFORMA de ${this.state.name}, de numero ${this.state.phone} con su mensaje ${this.state.body}`,
          })
          console.log("send")
          this.setState({ operationStatus: true, modalOpen: true })
        } catch (error) {
          console.log(error)
          this.setState({ operationStatus: false, modalOpen: true })
        }
    }
   componentDidMount = async ()=>{
      const add = await addVisits();
      console.log(add)
      const visits =  await GetVisits();
      console.log(visits)
      this.setState({visits: visits.visits?(visits.visits):('Cargando...')})
    }

    render (){
      const { classes } = this.props;

      const mapOffice =(officeName)=>
      {
          switch(officeName){
              case 'IRAPUATO': return ('https://goo.gl/maps/2fZFCbp2o4tSdffx9')
              case 'CELAYA': return('https://goo.gl/maps/xZUjGK6LQpWhf9DeA')
              case 'SILAO': return('https://goo.gl/maps/RmGkFnxRtAM4Jn97A')
              case 'VILLAGRÁN': return('https://goo.gl/maps/iQTYXE3mCRa3qU4X9')
              case 'SAN_JOSÉ_ITURBIDE': return('https://goo.gl/maps/HFt7zNg96zxHLJ7R9')
              default: return ('https://goo.gl/maps/2fZFCbp2o4tSdffx9')
          }
      }
      return(
          <Grid container
          justify="center"
          alignItems="center"
          className={classes.footer}>
            <Grid 
            container
            justify="center"
            alignItems="center"
            className={classes.formTitle}>
              {/* <Grid item xs={2} className={classes.siteMap}>
              <Typography className={classes.siteMapText}>SiteMap</Typography>
                  
                      
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/tickets'>Consultas</Link></Typography>
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/'>Inicio</Link></Typography>
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/articulo'>Blog</Link></Typography>
                  
                      
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/programas'>Programas</Link></Typography>
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/revistas'>Revistas</Link></Typography>
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/eventos'>Eventos</Link></Typography>
                    
                  <Typography><Link style={{ textDecoration: 'none', color: 'white', }} to='/descuentos'>Descuentos</Link></Typography>
                      
              </Grid> */}
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}
              container
              justify="start"
              alignItems="start"
              direction="column"
              className={classes.socialMedia}>

                      <Typography className={classes.formTitle}>Redes Sociales SITIMM</Typography> 
                      <Grid>
                      <Grid item className={classes.s}>
                        <a href='https://www.facebook.com/SITIMM-779875035474175/' target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}><Facebook style={{ fontSize: 40 }}/></a>
                        <a href='https://twitter.com/sitimm_ctm?lang=es' target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none', color: 'inherit'}}><Twitter style={{ fontSize: 40 }}/></a>
                        <a href='https://www.youtube.com/channel/UCe72e-5oBMwf-FPARAzRg9Q' target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}><YouTube style={{ fontSize: 40 }}/></a>
                      </Grid>
                      <Typography className={classes.formTitle}> Páginas asociadas</Typography> 
                      <Grid item className={classes.s}>
                      <a href='http://www.sitimm.org/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}><img src={sitimm} style={{ width: 50}}/></a>
                      <a href='http://www.bibliotecaformativa.mx/' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}><img src={bibliotecaIMG} alt="Biblioteca" style={{ width: 210}}/></a>

                      </Grid>
                      </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}
              container
              justify="center"
              alignItems="center"
              className={classes.form}
              className={classes.B2}
              >
                  <Typography className={classes.formTitle}>Contacto</Typography>

                  <Grid 
                  direction="row"
                  container
                  justify="start"
                  alignItems="start"
                  >
                  <Grid direction="column"
                  item
                  xs={6}
                  justify="start"
                  alignItems="start"
                  
                  >     
                  <TextField  inputProps={{
                   className: classes.multilineColor}}label="Nombre:"
                  onChange={(event)=>{this.setState({name: event.target.value})}} value={this.state.name}/>
                  <TextField label="Email:" className={classes.TextField}
                    onChange={(event)=>{this.setState({email: event.target.value}) }} value={this.state.email} />
                  <TextField label="Teléfono :" className={classes.TextField} 
                    onChange={(event)=>{this.setState({phone: event.target.value}) }} value={this.state.phone}/>
                  
                  </Grid>
                  <Grid direction="column"
                  item
                  xs={5}
                  container
                  justify="center"
                  alignItems="center"
                  >     
                  
                  <TextField 
                  label="Mensaje:"
                  variant="outlined"
                    className={classes.TextField}
                    rows="4"
                    multiline
                    inputProps={{
                      classes: classes.multilineColor}}
                    onChange={(event)=>{this.setState({body: event.target.value})}}
                    value={this.state.body}
                    ></TextField>
                    <spam onClick={()=>this.sendMail()}className={classes.customButton}>Enviar</spam>
                  </Grid>


                  </Grid>   
                  
                  </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}
              container
              justify="center"
              alignItems="center"
              direction="column"
              className={classes.C3}
              >
                  <Typography className={classes.formTitle}>Nuestras Oficinas</Typography>                                  
                  <StaticQuery
                      query={graphql`query officeNT {
                        allCeformaPagesShow5 {
                          edges {
                            node {
                              data {
                                page {
                                  content {
                                    Oficinas {
                                      Nombre
                                      Telefono
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      `}
                      render={data => {
                        const offices =data.allCeformaPagesShow5.edges[0].node.data.page.content.Oficinas
                        return(
                          <Grid className={classes.text}> 
                          <a href={mapOffice(offices[0].Nombre.toUpperCase().replace(/ /g, "_"))} target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                            <Typography className={classes.text}>{offices[0].Nombre+ ' '+offices[0].Telefono}</Typography>     
                          </a>
                          <a href={mapOffice(offices[1].Nombre.toUpperCase().replace(/ /g, "_"))} target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                            <Typography className={classes.text}>{offices[1].Nombre+ ' '+offices[1].Telefono}</Typography>     
                          </a>
                          <a href={mapOffice(offices[2].Nombre.toUpperCase().replace(/ /g, "_"))} target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                            <Typography className={classes.text}>{offices[2].Nombre+ ' '+offices[2].Telefono}</Typography>     
                          </a>
                          <a href={mapOffice(offices[3].Nombre.toUpperCase().replace(/ /g, "_"))} target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                            <Typography className={classes.text}>{offices[3].Nombre+ ' '+offices[3].Telefono}</Typography>     
                          </a>
                          <a href={mapOffice(offices[4].Nombre.toUpperCase().replace(/ /g, "_"))} target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                            <Typography className={classes.text}>{offices[4].Nombre+ ' '+offices[4].Telefono}</Typography>     
                          </a>
                        </Grid>
                        )}}
                      />
                  {/* <a href='https://goo.gl/maps/qc9DSwMQbCW59mGU6' target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                  <Typography className={classes.text}>SITIMM IRAPUATO (462) 626 06 97 y (462) 626 25 50</Typography>     
                  </a>
                  <a href='https://goo.gl/maps/PuMdminZ5cuvLcpN8' target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                  <Typography className={classes.text}>SITIMM SILAO (472) 722 35 </Typography>     
                  </a>
                  <a href='https://goo.gl/maps/Y7Tbg6iErCvNhAQT7' target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                  <Typography className={classes.text}>SITIMM CELAYA (461) 608 02 30</Typography>     
                  </a>
                  <a href='https://goo.gl/maps/qbNt36DafBw1iEzU9' target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                  <Typography className={classes.text}>SITIMM VILLAGRÁN (411) 165 31 49</Typography>     
                  </a>
                  <a href='https://goo.gl/maps/PJpqEdzAi4rkJgZ57' target="_blank"  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>
                  <Typography className={classes.text}>SITIMM SAN JOSÉ ITURBIDE (419) 198 25 51</Typography>     
                  </a> */}
                  
              </Grid>
              </Grid>
              <Grid item
              justify="center"
              alignItems="center">
                <Typography className={classes.text}>Visitas: {this.state.visits}</Typography>
              </Grid>
              <Modal
                open={this.state.modalOpen}
                onClose={() => {
                  this.setState({ modalOpen: false })
                }}
                className={classes.modal}
              >
                <div className={classes.paper}>
                  {this.state.operationStatus ? (
                    <p>Su mensaje a sido enviado correctamente</p>
                  ) : (
                    <p>Ocurrió un error, inténtelo de nuevo</p>
                  )}
                </div>
              </Modal>
          </Grid>
    )}
}

footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(footer)