import { navigate } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import Logo from '../images/ceforma.png'
import { makeStyles } from '@material-ui/core/styles';
import {
  Menu,
  MenuItem,
  Typography, 
  Button, 
  Grid,
  Dialog
} from '@material-ui/core';
import Image from 'react-bootstrap/Image'
import MenuIcon from '@material-ui/icons/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles(theme => ({
   img:{
    width: '18vh',
    height: '18vh',
    padding: 15,
    '@media (max-width: 477px)': {
      width: '50px',
      height: '50px',
      padding: "5px",
    },
    '@media (max-width: 320px)': {
      display:"none"
    },
   },

   header:{
    margin: `0 auto`,
    maxWidth: 1060,
    padding: `1.45rem 1.0875rem`,
   },
   title:{
     color: 'white',     
     fontWeight: 'bold',
     fontSize: '4vh',
     '@media (max-width: 477px)': {
      fontSize: '12px',
    },
   },
   button: {
    textTransform: 'none',
    height:"8vh",
    color:"white",
    //fontWeight: "bold",
    //borderLeft: "1px solid #38546d",
    //borderRight: "1px solid #16222c",
    '@media (max-width: 477px)': {
      display:"none"
    },
    '&:hover': {
      //background: "#f00",
      fontWeight: 'bold',
      color: 'red'
   },
  },
  nav:{
    backgroundColor:'black'
  }, 
  menubtn:{
    //color: 'black'
    textTransform: 'none',
    '&:hover': {
      //background: "#f00",
      fontWeight: 'bold',
      color: 'red'
  }},
  menu:{
    flexDirection: 'column',
    alignItems: 'start'
  },
  memuBoton:{
    display:"none",
    color:"white",
    '@media (max-width: 477px)': {
      display:"block"
    },
    '&:hover': {
      //background: "#f00",
      fontWeight: 'bold',
   },
  },
  Center:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },
  DialogContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginTop:"10vh"
  },
  btnDialog:{
    width:"100%",
    marginTop:"5px",
  }


}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
}); 
export default ({ siteTitle }) => {
  const classes = useStyles();  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick= link => () => {
    navigate(link)
  }
  const openMenu=(event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseD = () => {
    setOpen(false);
  };
  return (
    <header
    style={{
      background: `red`,
      marginBottom: `1.45rem`,
      fontFamily: 'Open Sans, sans-serif',
    }}
  >
  {/*  <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        > 
          <Grid className= {classes.img}>
           <Logo/>
          </Grid>
            {'Centro de Formación Laboral A.C.'}
        </Link>
      </h1>
    </div>*/}
   <Grid container 
    direction="row" 
    justify="center" 
    alignItems="center"
    classes={classes.header}>
      <Button className={classes.memuBoton} onClick={handleClickOpen}><MenuIcon/></Button>
      <Image className={classes.img }src = {Logo}/>
      <Typography className={classes.title}>
        Centro de Formación Laboral A.C.
      </Typography>
    </Grid>

    <Grid container 
   direction="row"
   justify="center"
   alignItems="center"
   className={classes.nav}
   >
      <Button className={classes.button} onClick={handleClick('/')}>Inicio</Button>
      <Button className={classes.button} onClick={handleClick('/Conocenos')}>Conocenos</Button>
      <Button className={classes.button} onClick={handleClick('/Nexos')}>Nexos Internacionales</Button>
      <Button className={classes.button} onClick={handleClick('/revistas')}>Revista</Button>
      <Button className={classes.button} onClick={openMenu}>Capacitación ▼</Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem onClick={handleClose} className={classes.menu}><Button className={classes.menubtn} onClick={handleClick('/capacitacion/empresas')}>Capacitación para Empresas</Button>
        <Button className={classes.menubtn} onClick={handleClick('/Catalogo')}>Catálogo de Capacitación</Button>
        <Button className={classes.menubtn} onClick={handleClick('/capacitacion/sitimm')}>Capacitación SITIMM</Button></MenuItem>
      </Menu>
      <Button className={classes.button} onClick={handleClick('/calendario')}>Calendario</Button>
      
      <Button className={classes.button} onClick={handleClick('/servicios')}>Servicios</Button>
      <Button className={classes.button} onClick={handleClick('/eventos')}>Eventos</Button>
      <Button className={classes.button} onClick={handleClick('/biblioteca')}>Biblioteca Formativa</Button>
      <Button className={classes.button} onClick={handleClick('/convenios')}>Convenios</Button>
      <Button className={classes.button} onClick={handleClick('/oficinas')}>Oficinas</Button>
   </Grid>


   <Dialog fullScreen open={open} onClose={handleCloseD} TransitionComponent={Transition}>
        <AppBar style={{
          backgroundColor:"red"
        }}>
            <Grid container>
              <Grid item xs={3}
               className={classes.Center}
              >
                <IconButton edge="start" color="inherit" onClick={handleCloseD} aria-label="close">
              <CloseIcon />
            </IconButton>
              </Grid>
              <Grid 
              item
              xs={9}
              className={classes.Center}
               >
                 <Image className={classes.img }src = {Logo}/>
                <Typography className={classes.title}>
                  Centro de Formación Laboral A.C.
                </Typography>
              </Grid>
            </Grid>
        </AppBar>
        <Grid container className={classes.DialogContainer}>
          <Button className={classes.btnDialog} onClick={handleClick('/')}>Inicio</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/Conocenos')}>Conocenos</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/Nexos')}>Nexos Internacionales</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/calendario')}>Calendario</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/servicios')}>Servicios</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/eventos')}>Eventos</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/biblioteca')}>Biblioteca Formativa</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/convenios')}>Convenios</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/oficinas')}>Oficinas</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/Catalogo')}>Catálogo de Capacitación</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/capacitacion/sitimm')}>Capacitación SITIMM</Button>
          <Button className={classes.btnDialog} onClick={handleClick('/capacitacion/empresas')}>Capacitación para Empresas</Button>
        </Grid>
      
      </Dialog>
  </header>

  )
}

/* Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
 */